import styled from 'styled-components';
import { tombac } from 'tombac';

const BadgeContainer = styled.div`
  ${tombac.text()}
  height: ${tombac.space(2.5)};
  width: ${tombac.space(5.5)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${tombac.color('accent', 500)};
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: 700;
  color: ${tombac.color.white};
`;

interface BadgeProps {
  text: string;
  style?: React.CSSProperties;
}

export const Badge: React.FC<BadgeProps> = ({ text, style }) => {
  return <BadgeContainer style={style}>{text}</BadgeContainer>;
};
