import LimitsApi from 'api/LimitsApi';
import { PassMatrixTooltip } from 'components/UI/PassMatrixTooltip';
import { HStack, VStack } from 'components/UI/Stack/Stack';
import { useValidation } from 'hooks/useValidation';
import analysisTypeName from 'logic/analysis/analysisTypeName';
import { AnalysisType } from 'model/AnalysisDto';
import { MapMatchOption } from 'model/AnalysisParameters';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuState, useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  Heading,
  Input,
  Label,
  Modal,
  ToggleGroup,
  ModalContent,
  ModalHeader,
  TooltipOnHover,
  ModalActions,
} from 'tombac';
import { ChevronDownIcon, ChevronUpIcon } from 'tombac-icons';
import { SimpleSelect } from 'tombac/dist/components/Form/SimpleSelect';
import { useIsAdmin } from 'user';
import { ModalForm, Text, ValidationMessagesSection } from '../UI/FormUI';
import { DataSourceSelect } from './DataSourceSelect';
import odSelectedImg from './origin-destination--selected.svg';
import odImg from './origin-destination.svg';
import { nameRequirements } from './Requirements/requirements';
import selectedLinkSelectedImg from './selected-link--selected.svg';
import selectedLinkImg from './selected-link.svg';
import { VehicleTypesRadio } from './VehicleTypesRadio';

const NewReportModalHeader = styled(ModalHeader)`
  padding: 0 24px;
  margin: 0;
  height: 60px;
  align-content: center;
  border-bottom: 1px solid #e5e5e5;
`;

const NewReportModalActions = styled(ModalActions)`
  padding: 0 24px;
  height: 60px;
  align-items: center;
  margin: 0;
  border-top: 1px solid #e5e5e5;
`;

const BigCardContainer = styled.div<{ active?: boolean; margin?: string }>`
  height: 130px;
  width: 360px;
  padding: 16px;
  display: grid;
  grid-template-columns: 140px auto;
  column-gap: 0px;
  border-radius: 10px;
  cursor: pointer;
  border: ${(p) => (p.active ? 'solid 1px #8dc3eb' : 'solid 1.1px #e5e5e5')};
  background: ${(p) => (p.active ? '#f6fafe' : '#ffffff')};
  color: ${(p) => (p.active ? '#2a90d5' : undefined)};
  margin: ${(p) => p.margin};
`;

function CardItem({
  img,
  label,
  description,
  active,
  margin,
  onClick,
}: {
  img?: any;
  label: any;
  description: string;
  active: boolean;
  margin?: string;
  onClick?: () => void;
}) {
  return (
    <BigCardContainer active={active} margin={margin} onClick={onClick}>
      <img src={img} style={{ width: '100%', alignSelf: 'center' }} />
      <div>
        <Text
          display="flex"
          style={{ alignItems: 'center' }}
          fontSize="14px"
          marginLeft="8px"
          marginRight="8px"
          color={active ? '#2a90d5' : '#000000'}
          fontWeight="600"
        >
          {label}
        </Text>
        <Text
          display="block"
          marginLeft="8px"
          marginRight="8px"
          marginTop="6px"
          lineHeight="1.5"
          color={active ? '#2a90d5' : undefined}
        >
          {description}
        </Text>
      </div>
    </BigCardContainer>
  );
}

function PassMatrixSelector({
  passMatrix,
  setPassMatrix,
}: {
  passMatrix?: boolean;
  setPassMatrix: (value: boolean | undefined) => void;
}) {
  return (
    <FormGroup label="Trips outside regions">
      <Box>
        <ToggleGroup.Root
          $width="360px"
          value={passMatrix?.toString()}
          groupContainerProps={{ $width: '100%' }}
          onChange={(e) => setPassMatrix(e === 'true')}
        >
          <ToggleGroup.Item value={'false'}>
            <TooltipOnHover
              content={<PassMatrixTooltip passMatrix={false} />}
              placement="top"
            >
              Add to external region
            </TooltipOnHover>
          </ToggleGroup.Item>
          <ToggleGroup.Item value={'true'}>
            <TooltipOnHover
              content={<PassMatrixTooltip passMatrix={true} />}
              placement="top"
            >
              Trim to defined region
            </TooltipOnHover>
          </ToggleGroup.Item>
        </ToggleGroup.Root>
      </Box>
    </FormGroup>
  );
}

function Collapsable({ children }: { children: ReactNode | undefined }) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Box>
      <Text
        onClick={() => setCollapsed(!collapsed)}
        fontSize="12px"
        fontWeight="600"
        color="#000"
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Advanced Settings{' '}
        <Box $ml="8px">
          {collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </Box>
      </Text>
      {collapsed ? null : <Box $pt="20px">{children}</Box>}
    </Box>
  );
}

export const NewReportModal = ({
  isOpen,
  onClose,
  action,
}: {
  isOpen: boolean;
  onClose: (action: 'cancel' | 'submit') => any;
  action: 'new' | 'edit' | 'clone';
}) => {
  const [menu, setMenu] = useMenu();
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState(menu.name);
  const [type, setType] = useState(menu.type);
  const [passMatrix, setPassMatrix] = useState<boolean | undefined>(
    menu.passMatrix,
  );
  const organizations = menu.organizations;
  const limits = LimitsApi.use();
  const history = useHistory();
  const isAdmin = useIsAdmin();
  const validation = useValidation(
    nameRequirements,
    { menu: { name, passMatrix, organizations } as MenuState },
    () => {},
  );

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const { title, submitButtonText } = {
    title: {
      new: 'Create new report',
      clone: 'Clone report',
      edit: 'Report settings',
    }[action],
    submitButtonText: {
      new: 'Create',
      clone: 'Clone',
      edit: 'Update',
    }[action],
  };

  const updateReportSettings = () => {
    setMenu({
      name,
      passMatrix,
      organizations,
      type,
      links: type === 'FLOW_MATRIX' ? [] : menu.links,
      mapType:
        menu.mapType === 'OPEN_DSEG' && menu.type === AnalysisType.SelectedLink
          ? 'DSEG_NOSPLIT'
          : menu.mapType,
      mapTypeName:
        menu.mapType === 'OPEN_DSEG' && menu.type !== AnalysisType.SelectedLink
          ? 'Orbis'
          : 'Genesis',
    });
  };

  if (!isOpen) {
    return null;
  }

  const showAdvancedOptions =
    isAdmin ||
    limits?.limits?.limitedDataSourcesEnabled ||
    limits?.limits?.excludeMobileSourcesEnabled;

  const isFlowMatrix = type === AnalysisType.FlowMatrix;
  const isFlowLinks = type === AnalysisType.FlowLinks;

  return (
    <Modal isOpen onRequestClose={() => onClose('cancel')}>
      <NewReportModalHeader>
        <Heading fontWeight="bold" level={2} fontSize={16}>
          {title}
        </Heading>
      </NewReportModalHeader>
      <ModalContent style={{ maxWidth: '790px', margin: '0' }}>
        <ModalForm>
          <HStack>
            <FormGroup
              label="Choose name"
              $width="100%"
              error={
                validation.messages !== null && validation.messages.length > 0
              }
            >
              <Input
                placeholder="Report name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                ref={nameInputRef}
                invalid={
                  validation.messages !== null && validation.messages.length > 0
                }
                data-test="new-report-name-input"
              />
            </FormGroup>
          </HStack>

          <Box>
            <HStack>
              <Label>Report type</Label>
            </HStack>
            <VStack style={{ gap: 2, marginTop: '8px' }}>
              <HStack style={{ gap: 2 }}>
                <CardItem
                  img={type === AnalysisType.FlowMatrix ? odSelectedImg : odImg}
                  label={analysisTypeName(AnalysisType.FlowMatrix)}
                  description="Analyze origin and destination of trips which started, passed through or ended within your defined regions."
                  active={type === AnalysisType.FlowMatrix}
                  onClick={() => setType(AnalysisType.FlowMatrix)}
                />
                <CardItem
                  img={
                    type === AnalysisType.SelectedLink
                      ? selectedLinkSelectedImg
                      : selectedLinkImg
                  }
                  label={analysisTypeName(AnalysisType.SelectedLink)}
                  description="Analyze inbound and outbound trips through the selected road segment."
                  active={type === AnalysisType.SelectedLink}
                  onClick={() => setType(AnalysisType.SelectedLink)}
                />
              </HStack>
              {limits?.limits?.flowLinksEnabled && (
                <CardItem
                  img={type === AnalysisType.FlowLinks ? odSelectedImg : odImg}
                  label={analysisTypeName(AnalysisType.FlowLinks)}
                  description="Analyze the patterns of trips that have passed through your defined regions"
                  active={type === AnalysisType.FlowLinks}
                  onClick={() => setType(AnalysisType.FlowLinks)}
                />
              )}
            </VStack>
          </Box>

          <HStack style={{ justifyContent: 'space-between' }}>
            {isFlowMatrix && (
              <PassMatrixSelector
                passMatrix={passMatrix}
                setPassMatrix={setPassMatrix}
              />
            )}
            <FormGroup label="Vehicle types">
              <VehicleTypesRadio />
            </FormGroup>
          </HStack>

          {showAdvancedOptions && (
            <Collapsable>
              <Box $flexWrap="wrap" $display="flex">
                {limits?.limits?.limitedDataSourcesEnabled && (
                  <Box $flexGrow="1">
                    <DataSourceSelect />
                  </Box>
                )}
                {isAdmin && (
                  <>
                    <Box $flexGrow="1">
                      <FormGroup label="Map Matching">
                        <SimpleSelect
                          value={menu.mapMatchOption}
                          onChange={(value) =>
                            setMenu({ mapMatchOption: value })
                          }
                          options={[
                            { label: 'Auto', value: MapMatchOption.Auto },
                            { label: 'Enabled', value: MapMatchOption.Enabled },
                            {
                              label: 'Disabled',
                              value: MapMatchOption.Disabled,
                            },
                          ]}
                        />
                      </FormGroup>
                    </Box>
                    {(isFlowMatrix || isFlowLinks) && (
                      <Box $flexGrow="1">
                        <FormGroup label="Trip stats">
                          <Checkbox
                            $paddingLeft="8px"
                            checked={menu.tripStats}
                            variant="toggle"
                            onChange={(e) =>
                              setMenu({ tripStats: e.target.checked })
                            }
                          />
                        </FormGroup>
                      </Box>
                    )}
                  </>
                )}
                {(limits?.limits?.excludeMobileSourcesEnabled || isAdmin) && (
                  <Box $flexGrow="1">
                    <FormGroup label="Exclude mobile data">
                      <Checkbox
                        checked={menu.excludeMobile}
                        variant="toggle"
                        onChange={(e) =>
                          setMenu({ excludeMobile: e.target.checked })
                        }
                      />
                    </FormGroup>
                  </Box>
                )}
                {isAdmin && (
                  <Box $flexGrow="1">
                    <FormGroup label="Save debug traces data">
                      <Checkbox
                        checked={menu.saveDebugTraces}
                        variant="toggle"
                        onChange={(e) =>
                          setMenu({ saveDebugTraces: e.target.checked })
                        }
                      />
                    </FormGroup>
                  </Box>
                )}
              </Box>
            </Collapsable>
          )}
        </ModalForm>

        <ValidationMessagesSection bottom="0px">
          {validation.messages}
        </ValidationMessagesSection>

        <NewReportModalActions>
          <Button variant="flat" onClick={() => onClose('cancel')}>
            Cancel
          </Button>

          <Button
            onClick={() => {
              if (validation.canSubmit()) {
                updateReportSettings();
                onClose('submit');
                history.push('/new/dates');
              }
            }}
            variant="primary"
            data-test="modal-create-button"
          >
            {submitButtonText}
          </Button>
        </NewReportModalActions>
      </ModalContent>
    </Modal>
  );
};
