import { Button, tombac } from 'tombac';
import { CancelIcon } from 'tombac-icons';
import { BoldText, GreyText } from '../Text.style';
import styled from 'styled-components';
import { Badge } from './Badge';

const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 500px 50px;
  align-items: center;
  height: 65px;
  padding-left: 80px;
  background: ${tombac.color('accent', 100)};
  border-bottom: 1px solid ${tombac.color('accent', 400)};
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${tombac.color('accent', 400)};
  height: 100%;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-self: flex-end;
  gap: 40px;
  position: relative;
  padding-right: ${tombac.space(3)}; ;
`;

interface BannerLinkButtonsInfo {
  name: string;
  url: string;
}

interface NewBannerProps {
  onCloseBanner: () => void;
  header: JSX.Element | string;
  content: JSX.Element | string;
  linkButtons?: BannerLinkButtonsInfo[];
}

export const NewBanner: React.FC<NewBannerProps> = ({
  onCloseBanner,
  header,
  content,
  linkButtons,
}) => {
  return (
    <BannerContainer>
      <Badge text="NEW" />
      <TextContainer>
        <BoldText>{header}</BoldText>
        <GreyText>{content}</GreyText>
      </TextContainer>
      <LinkButtonsContainer>
        {linkButtons?.map((button) => (
          <Button
            key={button.name}
            size="xs"
            variant="primary"
            shape="circle"
            $background="--accent-500"
            $padding="10px"
            onClick={() => window.open(button.url, '_blank')}
          >
            {button.name}
          </Button>
        ))}
      </LinkButtonsContainer>
      <CloseButtonContainer>
        <Button
          size="s"
          shape="circle"
          $background="transparent"
          variant="flat"
          onClick={onCloseBanner}
        >
          <CancelIcon color="#00AAFF" size="lg" />
        </Button>
      </CloseButtonContainer>
    </BannerContainer>
  );
};
