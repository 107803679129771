import { NewBanner } from './NewBanner';

interface OrbisNewBannerProps {
  onCloseBanner: () => void;
}

export const OrbisNewBanner: React.FC<OrbisNewBannerProps> = ({
  onCloseBanner,
}) => {
  return (
    <NewBanner
      header="New Orbis map enabled!"
      content="New generation map data is now available."
      linkButtons={[
        {
          name: 'Read more about Orbis',
          url: 'https://www.tomtom.com/tomtom-orbis-maps/',
        },
      ]}
      onCloseBanner={onCloseBanner}
    />
  );
};
